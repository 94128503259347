<template>
  <div class="relative flex items-center justify-center min-h-screen px-4 bg-gray-100 sm:px-0">
    <div class="absolute bottom-0 z-0 w-full">
      <img src="@/assets/img/cities-and-cars-shuttlers.png" class="w-full auth-img">
    </div>
    <div
      class="absolute top-0 flex items-center justify-center w-full py-4 text-white bg-gray-type-1"
      style="height: 72px">
      <app-logo />
    </div>
    <div class="z-10 w-full max-w-lg px-6 py-12 pt-8 pb-12 mt-24 bg-white border rounded-xl sm:px-16">
      <p class="font-bold text-center text-gray-type-2">
        {{ titleText }}
      </p>
      <div
        class="flex items-start p-3 my-5 bg-red-200 rounded-md"
        v-if="errorMessage">
        <div class="text-sm font-medium text-red-500">{{ errorMessage }}</div>
      </div>

      <template v-if="isLoginStep">
        <p class="mt-2 text-xs font-medium text-center text-gray-type-3">
          Don’t have a business account?
          <router-link class="text-green-type-1" to="/register">
            Register
          </router-link>
        </p>
        <form>
          <template v-if="isLoginStep">
            <ul class="flex justify-center space-x-2 text-white mt-8">
              <li>
                <span
                    @click="setLoginType('email')"
                    class=" inline-block cursor-pointer px-4 py-2 text-sm font-medium rounded-md outline-none text-gray-500"
                    :class="{'bg-green-50 text-green-500': isEmailLogin}">
                  Email address
                </span>
              </li>
              <li>
                <span
                    @click="setLoginType('phone')"
                    class=" inline-block cursor-pointer px-4 py-2 text-sm font-medium rounded-md outline-none text-gray-500"
                    :class="{'bg-green-50 text-green-500': isPhoneLogin}">
                  Phone number
                </span>
              </li>
            </ul>

            <div class="mt-8" v-if="isEmailLogin">
              <label class="text-xs font-medium text-gray-type-3" for="email">Email address</label>
              <div class="mt-2">
                <input
                    v-model="$v.form.email.$model"
                    type="email"
                    id="email"
                    name="email"
                    class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
              </div>
              <span
                  class="text-xs font-bold text-red-400"
                  v-if="$v.form.email.required.$invalid && $v.form.email.$error">
                Please provide an email</span>
              <p
                  class="text-xs mb-0 font-bold text-red-400"
                  v-if="!$v.form.email.email.$invalid && $v.form.email.$error">
                Please provide a valid email</p>
            </div>

            <div class="mt-8" v-if="isPhoneLogin">
              <label class="text-xs font-medium text-gray-type-3" for="email">Phone Number</label>
              <div class="mt-4 flex flex-row">
                <select
                    class="h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                    v-model="form.country"
                >
                  <option
                      :key="country.code"
                      v-for="country in countries"
                      v-bind:value="country"
                  >
                    {{ countryCodeToEmoji(country.code) }}
                    {{ country.phone_code }}
                  </option>
                </select>

                <input
                    v-model="form.phone"
                    type="tel"
                    id="phone"
                    name="phone"
                    class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                    placeholder=""
                />
              </div>

              <span
                  class="text-xs font-bold text-red-400"
                  v-if="($v.form.phone.required.$invalid && $v.form.phone.$error)">
                Please provide a phone number</span>
              <p
                  class="mb-0 text-xs font-bold text-red-400"
                  v-if="$v.form.phone.$dirty && !isPhoneValid">
                Please provide a valid phone number</p>
            </div>
            <app-button
                type="submit"
                class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
                :loading="processingLogin"
                @click="proceedToPassword">
              Proceed
            </app-button>
          </template>
        </form>
      </template>



      <template v-if="isPasswordStep">
        <p class="mt-4 text-sm text-center" v-html="actionText"></p>
        <form class="mt-12">
          <template v-if="isOTPAccess">
            <div class="mt-6">
              <label class="flex justify-end" for="password">
                <span
                    class="text-xs font-medium text-green-type-1 cursor-pointer"
                    @click.prevent.stop="setAccessMode('password')">
                Login with password
              </span>
              </label>
              <div class="relative mt-2">
                <div class="flex justify-center mt-3">
                  <v-otp-input
                      ref="otpInput"
                      class="justify-between w-full"
                      input-classes="otp-input w-16 h-16 rounded bg-gray-type-4 focus:outline-none focus-visible:border-gray-600 text-center "
                      separator=""
                      :num-inputs="4"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-change="handleOnChange"
                      @on-complete="handleOnComplete"
                  />
                </div>

                <div class="text-center mt-6 text-xs">
                  <p class="font-normal">Didn't receive code?</p>
                  <div class="mt-1">
                    <countdown-timer
                        value="120"
                        @on-timer-restart="initiateOtp"
                    />
                  </div>
                </div>
              </div>
            </div>
            <app-button
                type="button"
                class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
                :loading="processingLogin" @click="loginWithOTP">
              Log In
            </app-button>
          </template>

          <template v-if="!isOTPAccess">
            <div class="mt-6">
              <label class="flex justify-between" for="password">
              <span class="text-xs font-medium text-gray-type-3">
                Password
              </span>
                <span
                    class="text-xs font-medium text-green-type-1 cursor-pointer"
                    @click.prevent.stop="setAccessMode('otp')">
                Login with OTP
              </span>
              </label>
              <div class="relative mt-2">
                <input
                    v-model="$v.form.password.$model"
                    :type="showPassword ? 'text' : 'password'"
                    id="password"
                    name="password"
                    class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                    required
                />
                <div
                    class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
                    @click="showPassword = !showPassword"
                >
                  {{ showPassword ? "Hide" : "Show" }}
                </div>
                <p
                    class="mb-3 text-xs font-bold text-red-400"
                    v-if="$v.form.password.required.$invalid && $v.form.password.$error">
                Please provide a password</p>
              </div>
            </div>
            <router-link
                to="/forgot-password"
                class="mt-2 text-xs font-medium text-green-type-1">Forgot Password?
            </router-link>
            <app-button
                type="submit"
                class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
                :loading="processingLogin"
                @click="login">
              Log In
            </app-button>
          </template>

          <div
              class="mt-6 flex items-center justify-center cursor-pointer px-5"
              @click="goToStart()">
            <span>
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M6 12L18 12M11 7L6 12L11 7ZM6 12L11 17L6 12Z"
                stroke="#000005"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
        </span>
            <span class="text-xs font-bold text-black pt-1">Back</span>
          </div>
        </form>
      </template>

    </div>
  </div>
</template>

<script>
import AppLogo from "@/components/AppLogo";
import CountdownTimer from "@/components/CountdownTimer";
import VOtpInput from 'vue3-otp-input';
import {email, requiredIf} from "@vuelidate/validators";
import countryCodeEmoji from "country-code-emoji";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

export default {
  components: {
    CountdownTimer,
    AppLogo,
    VOtpInput
  },
  data() {
    return {
      showPassword: false,
      errorMessage: '',
      processingLogin: false,
      form: {
        email: '',
        password: '',
        phone: '',
        country: null,
        otpValue: ''
      },
      step: 1,
      accessMode: 'password',
      loginType: 'email',
      otpReferenceId: null,
      isPhoneValid: false,
      countries: []
    }
  },
  validations() {
    return {
      form: {
        email: {
          required: requiredIf(() => this.isEmailLogin),
          email,
        },
        password: {
          required: requiredIf(() =>!this.isOTPAccess)
        },
        phone: {
          required: requiredIf(() => this.isPhoneLogin)
        },
        otpValue: {
          required: requiredIf(() => this.isOTPAccess)
        }
      },
    }
  },

  computed: {
    isLoginStep() {
      return this.step === 1;
    },
    isPasswordStep() {
      return this.step === 2;
    },
    isEmailLogin() {
      return this.loginType === 'email';
    },
    isPhoneLogin() {
      return this.loginType === 'phone';
    },
    isOTPAccess() {
      return this.accessMode === 'otp';
    },
    titleText() {
      if(this.isLoginStep) {
        return 'Log in to your business account'
      }
      if(this.isOTPAccess) {
        return 'Confirm OTP';
      } else {
        return 'Enter your password';
      }
    },
    actionText() {
      if(!this.isOTPAccess) {
        return `You are about to login with <b>${this.isEmailLogin ? this.form.email : this.form.phone}</b>`
      } else {
        return `Please enter the four digit code sent to <b>${this.isEmailLogin ? this.form.email : this.form.phone}</b>`
      }
    }
  },
  watch: {
    "form.phone"() {
      this.validatePhoneNumber();
    },
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    countryCodeToEmoji(code) {
      return countryCodeEmoji(code);
    },
    async fetchCountries() {
      const response = await this.$http.get(`/countries`);
      this.countries = response.data;
      this.form.country = this.countries[0];
    },
    validatePhoneNumber() {
      this.isPhoneValid = isValidPhoneNumber(
          this.form.phone,
          this.form.country.code
      );
    },
    login() {
      this.$v.form.$touch();
      if (this.processingLogin || this.$v.form.$invalid) {
        return;
      }

      this.processingLogin = true;
      this.errorMessage = null;

      let payload = null;
      if (this.isEmailLogin) {
        payload = {
          email: this.form.email,
          password: this.form.password,
        };
      } else if(this.isPhoneLogin) {
        payload = {
          phone: this.form.phone,
          password: this.form.password,
          country_code: this.form.country.code,
        };
      }

      this.$store
        .dispatch("auth/login", { ...payload, type: "corporate" })
        .then(async () => {
          this.form.email = "";
          this.form.password = "";

          const redirect = this.$route.query.redirect;

          if (redirect) {
            this.$router.push({
              path: redirect,
            });
          } else {
            this.$router.push({
              name: "dashboard.home",
              query: { ...this.$route.query },
            });
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = "Oops! An error occurred, please try again.";
          }
        })
        .finally(() => (this.processingLogin = false));
    },
    proceedToPassword() {
      this.$v.form.email.$touch();
      this.$v.form.phone.$touch();
      if((this.isEmailLogin && !this.$v.form.email.$invalid) || (this.isPhoneLogin && this.isPhoneValid && !this.$v.form.phone.$invalid)) {
        this.step = 2;
        this.setAccessMode('password');
      }
    },
    goToStart() {
      this.step = 1;
      this.error = '';
    },
    setLoginType(type) {
      this.loginType = type;
    },
    setAccessMode(type) {
      this.accessMode = type;
      if(type === 'otp'){
        this.initiateOtp();
      }
    },
    async loginWithOTP() {
      this.$v.form.$touch();
      if (this.processingLogin || this.$v.form.$invalid) {
        return;
      }

      this.processingLogin = true;
      this.errorMessage = null;

      const payload = {
        otpCode: this.form.otpValue,
        type: "corporate",
        referenceId: this.otpReferenceId,
      };

      this.$store.dispatch('auth/otpLogin', payload)
          .then(async () => {
            this.form.email = "";
            this.form.password = "";
            this.form.otpValue = '';
            this.form.phone = '';

            const redirect = this.$route.query.redirect;
            if (redirect) {
              this.$router.push({
                path: redirect,
              });
            } else {
              this.$router.push({
                name: "dashboard.home",
                query: { ...this.$route.query },
              });
            }
      })
          .catch((err) => {
            if (err.response && err.response.data.message) {
              this.errorMessage = err.response.data.message;
            } else {
              this.errorMessage = "Oops! An error occurred, please try again.";
            }
          })
          .finally(() => (this.processingLogin = false));

    },
    handleOnChange(data) {
      if(data.length < 4) {
        this.form.otpValue = '';
      }
    },
    handleOnComplete(data) {
      this.form.otpValue = data;
    },
    async initiateOtp() {
      let payload = null;
      if (this.isEmailLogin) {
        payload = {
          email: this.form.email,
          type: "corporate",
        };
      } else {
        payload = {
          phone: this.form.phone,
          country_code: this.form.country.code,
          type: "corporate",
        };
      }
      const response = await this.$http.post(`/otp-login`, payload);
      this.otpReferenceId = response.data.reference_id;
    },
  },
  beforeUnmount() {
    this.$v.form.$reset();
  }
};
</script>